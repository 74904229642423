import {
  DollarCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileExcelOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { clearExpenseFilter } from "actions/expense-action";
import {
  Alert,
  Button,
  Col,
  Input,
  Layout,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography
} from "antd";
import {
  BoxColor,
  CardCompanyBalance,
  CardInfo,
  ColumnEmployee,
  FormatMoney,
  Header,
  InputCurrency,
  TableSearch
} from "components";
import { ColumnVehicle } from "components/table/column-vehicle";
import { translate } from "config/language";
import moment from "moment";
import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getAllBenefitCharge } from "services/benefit-service";
import { getEmployeeBalance } from "services/employee-service";
import { getExpenseReport } from "services/expense-service";
import { getVehicleBalance } from "services/vehicle-service";
import { themeColors } from "theme";
import { clearFormatedCPF, formatMoney, saveFileResume } from "utils";
import { importFile } from "utils/import-file";
import { pendingCharges, pendingSolicitation } from "./solicitation";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer,
  expense: state.expenseReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearExpenseFilter
    },
    dispatch
  );

const SolicitationSaveScreen = (props) => {
  const { login, balance, expense, clearExpenseFilter } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { type } = useParams();
  const employees = state.employees || [];
  const vehicles = state.vehicles || [];
  const carFleet = state.carFleet || false;
  const benefit = state.benefit || null;
  const selectedAdvanceIds = state.selectedAdvanceIds || null;
  const advance = !!selectedAdvanceIds;
  const alreadySelectedBenefit = state.alreadySelectedBenefit || null;
  const [isConfirmLoading, setIsConfirmLoading] = React.useState(false);
  const [start, setStart] = React.useState(true);
  const [isCompleteModalVisible, setIsCompleteModalVisible] =
    React.useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    React.useState(false);
  const [completeBalance, setCompleteBalance] = React.useState(false);
  const [importReport, setImportReport] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [viewBenefitsCharge, setViewBenefitsCharge] = React.useState(true);
  const [benefits, setBenefits] = React.useState([]);
  const [allbenefits, setAllBenefits] = React.useState([]);
  const [allbenefitsCharge, setAllBenefitsCharge] = React.useState([]);
  const [selectedBenefit, setSelectedBenefit] = React.useState(benefit);
  const [applyAll, setApplyAll] = React.useState({
    show: false,
    title: "",
    benefit: null,
    value: 0
  });
  const [importData, setImportData] = React.useState({
    show: false,
    text: null,
    type: carFleet ? "licensePlate" : "email"
  });
  const [modeEdit, setModeEdit] = React.useState(true);
  const [filterEmployees, setFilterEmployees] = React.useState([]);
  const [filterVehicles, setFilterVehicles] = React.useState([]);
  const [expenseReport, setExpenseReport] = React.useState({
    data: [],
    loading: false
  });

  const isAnyBalanceLoading = useMemo(() => {
    const isBalanceLoading = (item) => {
      const keys = Object.keys(item);
      return keys.some(
        (key) =>
          key.includes("benefit_") &&
          item[key].completeBalance &&
          item[key].balance === null
      );
    };

    return data.some(isBalanceLoading);
  }, [data]);
  const cannotOrder = useMemo(() => {
    const dataIsEmpty = data.length === 0;
    return dataIsEmpty || isAnyBalanceLoading;
  }, [data, isAnyBalanceLoading]);
  const loadBenefits = React.useCallback(async () => {
    setStart(true);
    const res = await getAllBenefitCharge(login.company.id, carFleet);

    const mapBenefit = (x) => ({
      ...x,
      value: x.value || 0,
      balance: null,
      total: 0,
      pendingExpense: 0,
      key: `benefit_${x.benefit}`,
      import: `(${x.benefit}) - ${x.name}`,
      //desabilita complemento de saldo para veiculos
      ...(advance && { completeBalance: false }),
      ...(carFleet && { completeBalance: false })
    });

    let benefits;
    if (!alreadySelectedBenefit) {
      benefits = res.benefits
        .filter((x) =>
          type === "incentive"
            ? !x.isBenefit && x.enabled
            : x.isBenefit && x.enabled
        )
        .map(mapBenefit);
    } else {
      benefits = res.benefits
        .filter((x) => x.benefit == alreadySelectedBenefit)
        .map(mapBenefit);
    }

    setAllBenefits(benefits);
    let benefitsCharge =
      type === "incentive" || alreadySelectedBenefit
        ? benefits
        : expense.createSolicitation
          ? benefits.filter((x) => x.benefit === expense.filter.benefit)
          : res.benefitsCharge
              .filter((x) => x.isBenefit && x.enabled)
              .map(mapBenefit);
    setAllBenefitsCharge(benefitsCharge);

    if (selectedBenefit) {
      let benefitSel = benefits.filter((x) => x.benefit == selectedBenefit)[0];
      benefits = [
        benefitSel,
        ...(benefitSel.parent
          ? benefits.filter((x) => x.benefit == benefitSel.parent)
          : [])
      ];
    }

    if (benefitsCharge.length == 0) setViewBenefitsCharge(false);

    await loadExpenseReport();
    setBenefits(
      benefitsCharge.length == 0 || selectedBenefit ? benefits : benefitsCharge
    );
  }, [selectedBenefit]);

  const loadBenefitsCharge = () => {
    setStart(true);
    setViewBenefitsCharge(!viewBenefitsCharge);
    if (viewBenefitsCharge) {
      setBenefits(allbenefits);
    } else {
      setBenefits(allbenefitsCharge);
    }
  };

  const completeModalHandler = () => {
    setIsCompleteModalVisible(false);
    setIsConfirmModalVisible(true);
  };
  const messageMinValue = React.useMemo(() => {
    let benefitsLength = benefits.filter((b) => !b.hasMinValue).length;
    if (type !== "incentive" && benefitsLength > 0) {
      let message = `O${benefitsLength > 1 ? "s" : ""} benefício${benefitsLength > 1 ? "s" : ""} `;
      benefits
        .filter((b) => !b.hasMinValue)
        .forEach((x, index, array) => {
          message += x.name;
          if (
            benefitsLength > 1 &&
            index < benefitsLength - 2 &&
            benefitsLength > 1
          ) {
            message += ", ";
          } else if (
            benefitsLength > 1 &&
            index < benefitsLength - 1 &&
            benefitsLength > 1
          ) {
            message += " e ";
          }
        });
      message += ` não possu${benefitsLength > 1 ? "em" : "i"} valor mínimo.`;
      return (
        <Alert
          message={message}
          type={"warning"}
          style={{ marginTop: 10 }}
          showIcon
        />
      );
    }
    return undefined;
  }, [benefits]);

  const loadData = React.useCallback(() => {
    if (benefits.length > 0) {
      const _data = [];
      if (carFleet) {
        vehicles.forEach((x) => {
          let obj = { vehicle: x };
          let vehicle = data.filter((e) => e.vehicle.id === x.id);
          benefits.forEach((benefit) => {
            if (vehicle.length === 0 || !vehicle[0][benefit.key]) {
              obj[benefit.key] = { ...benefit };
            } else {
              obj[benefit.key] = vehicle[0][benefit.key];
            }
          });
          _data.push(obj);
        });
      } else {
        employees.forEach((x) => {
          let obj = { employee: x };
          let employee = data.filter((e) => e.employee.id === x.id);
          benefits.forEach((benefit) => {
            if (employee.length === 0 || !employee[0][benefit.key]) {
              obj[benefit.key] = { ...benefit };
            } else {
              obj[benefit.key] = employee[0][benefit.key];
            }
            if (x.amount) {
              obj[benefit.key].value = x.amount;

              obj[benefit.key].total = x.amount;
            }
          });

          _data.push(obj);
        });
      }
      setData(_data);
      setStart(false);
    }
  }, [benefits]);

  const applyAllFunctions = {
    onShowApplyAll: (show, benefit = null) => {
      setApplyAll({
        show: show,
        title: benefit ? `Aplicar a todos: ${benefit.name}` : "",
        benefit: benefit,
        value: 0
      });
    },
    onConfirmApplyAll: () => {
      const newData = [...data];
      applyAllFunctions.onShowApplyAll(false);
      setData(
        newData.map((x) => {
          return {
            ...x,
            [applyAll.benefit.key]: {
              ...x[applyAll.benefit.key],
              value: applyAll.value
            }
          };
        })
      );
    }
  };

  const sumPendingExpense = (employeeId, balance, benefit) => {
    const report = expenseReport.data?.filter(
      (item) =>
        item.employee.id === employeeId && item.benefitBalance.id === benefit
    );
    if (report.length > 0) {
      const totalCharge = report.reduce((a, b) => a + b.totalCharge, 0);
      const totalExpense = report.reduce((a, b) => a + b.totalExpense, 0);
      const pending = totalCharge - balance - totalExpense;
      return pending < 0 ? 0 : pending;
    } else return 0;
  };

  const changeCompleteBalanceVehicle = (value, benefit) => {
    let _benefits = [...benefits];
    let objIndex = _benefits.findIndex((obj) => obj.benefit == benefit.benefit);
    _benefits[objIndex].completeBalance = value;
    setBenefits(_benefits);
    setStart(true);
  };

  const columns = React.useMemo(() => {
    const sumFaturaTotal = (e, record) => {
      const value = e - record.balance;
      return value < 0 ? 0 : value;
    };
    const createColumnCompleteBalance = (benefit) => {
      const cols = [
        {
          width: 200,
          align: "right",
          title: `${translate("balance")} Total`,
          dataIndex: benefit.key,
          render: (value, record, index) => (
            <InputCurrency
              value={value.total}
              onChange={(e) => {
                let valueSum = sumFaturaTotal(e, value);
                if (value.pendingExpense && value.pendingExpense !== null) {
                  valueSum = valueSum - value.pendingExpense;
                }
                onEditRow(
                  benefit.key,
                  index,
                  e,
                  carFleet ? record.vehicle.id : record.employee.id,
                  "total"
                );
                onEditRow(
                  benefit.key,
                  index,
                  valueSum < 0 ? 0 : valueSum,
                  carFleet ? record.vehicle.id : record.employee.id,
                  "value"
                );
              }}
            />
          )
        },
        {
          width: 150,
          align: "right",
          title: `${translate("balance")} Disponível`,
          dataIndex: benefit.key,
          render: (value) =>
            value.balance === null ? (
              <Spin size="small" />
            ) : (
              <FormatMoney value={value.balance} />
            )
        },
        {
          width: 150,
          align: "right",
          title: "Valor Carga",
          dataIndex: benefit.key,
          render: (value) => <FormatMoney value={value.value} />
        }
      ];
      if (benefit.companySpending && expense.createSolicitation) {
        cols.splice(2, 0, {
          width: 200,
          align: "right",
          title: `Gastos Pendentes`,
          dataIndex: benefit.key,
          render: (value, record, index) => {
            return value.pendingExpense === null ? (
              <Spin size={"small"} />
            ) : (
              <InputCurrency
                value={value.pendingExpense}
                onChange={(e) => {
                  const sumFinal = sumFaturaTotal(value.total, value) - e;
                  onEditRow(
                    benefit.key,
                    index,
                    e,
                    carFleet ? record.vehicle.id : record.employee.id,
                    "pendingExpense"
                  );
                  onEditRow(
                    benefit.key,
                    index,
                    sumFinal < 0 ? 0 : sumFinal,
                    carFleet ? record.vehicle.id : record.employee.id,
                    "value"
                  );
                }}
              />
            );
          }
        });
      }
      return cols;
    };

    const _columns = [];
    _columns.push({
      fixed: "left",
      width: type === "incentive" ? "auto" : 250,
      key: "name",
      dataIndex: carFleet ? "vehicle" : "employee",
      title: carFleet ? "Veículo" : "Colaborador",
      render: (value) =>
        carFleet ? (
          <ColumnVehicle
            name={value.name}
            licensePlate={value.licensePlate}
            code={value.code}
          />
        ) : (
          <ColumnEmployee
            name={value.name}
            email={value.email}
            cpf={value.cpf}
          />
        )
    });

    benefits.forEach((x) => {
      if (x.completeBalance && !alreadySelectedBenefit) {
        _columns.push({
          title: (
            <Row
              width="100%"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              {carFleet && (
                <Col
                  style={{ flex: 1, justifyContent: "left", display: "flex" }}
                >
                  <Tooltip title={"Complemento de saldo"}>
                    <Switch
                      style={{ backgroundColor: themeColors.darkColor }}
                      checked={x.completeBalance}
                      onChange={(value) =>
                        changeCompleteBalanceVehicle(value, x)
                      }
                    />
                  </Tooltip>
                </Col>
              )}
              <Col>
                <BoxColor color={x.color} />
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Space>{x.name}</Space>
              </Col>
            </Row>
          ),
          children: createColumnCompleteBalance(x)
        });
      } else {
        _columns.push({
          align: "right",
          width: 320,
          key: x.benefit,
          dataIndex: x.key,
          title: () => (
            <Row
              width="100%"
              style={{ alignItems: "center", justifyContent: "right" }}
            >
              {carFleet && (
                <Col
                  style={{ flex: 1, justifyContent: "left", display: "flex" }}
                >
                  <Tooltip title={"Complemento de saldo"}>
                    <Switch
                      style={{ backgroundColor: themeColors.darkColor }}
                      checked={x.completeBalance}
                      onChange={(value) =>
                        changeCompleteBalanceVehicle(value, x)
                      }
                    />
                  </Tooltip>
                </Col>
              )}
              <Col>
                <BoxColor color={x.color} />
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Space>
                  {x.name}
                  <Tooltip
                    placement="bottom"
                    title={`Aplicar valor para todos os colaboradores: ${x.name}`}
                  >
                    <DollarCircleOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => applyAllFunctions.onShowApplyAll(true, x)}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          ),
          render: (value, record, index) =>
            modeEdit ? (
              <InputCurrency
                value={value?.value}
                onChange={(e) =>
                  onEditRow(
                    x.key,
                    index,
                    e,
                    carFleet ? record.vehicle.id : record.employee.id
                  )
                }
              />
            ) : (
              <FormatMoney value={value.value} />
            )
        });
      }
    });
    return _columns;
  }, [data, benefits]);

  const sumTotal = React.useMemo(() => {
    return data.reduce(
      (x, y) => x + Object.keys(y).reduce((a, b) => a + (y[b].value || 0), 0),
      0
    );
  }, [data]);

  const sumTotalOnApprove = React.useMemo(() => {
    return data.reduce(
      (x, y) =>
        x +
        Object.keys(y).reduce(
          (a, b) =>
            a +
            (y[b].completeBalance
              ? y[b].total - (y[b].pendingExpense ?? 0)
              : y[b].value || 0),
          0
        ),
      0
    );
  }, [data]);

  const table = React.useMemo(() => {
    return (
      <Table
        title={() => (
          <Row>
            <Col xs={12}>
              <TableSearch
                data={data}
                filter={
                  carFleet
                    ? [
                        ["vehicle", "code"],
                        ["vehicle", "name"],
                        ["vehicle", "licensePlate"]
                      ]
                    : [
                        ["employee", "cpf"],
                        ["employee", "name"],
                        ["employee", "email"]
                      ]
                }
                filterNumber={false}
                justify={"start"}
                onSearch={(e, filtered) => {
                  carFleet ? setFilterVehicles(e) : setFilterEmployees(e);
                }}
              />
            </Col>
            <Col xs={12} style={{ justifyContent: "end" }}>
              {type !== "incentive" && !alreadySelectedBenefit && (
                <Row justify={"end"}>
                  <Button
                    style={{ marginTop: 10 }}
                    disabled={allbenefitsCharge.length == 0 || selectedBenefit}
                    size="small"
                    type={"link"}
                    onClick={() => loadBenefitsCharge()}
                  >
                    {!viewBenefitsCharge && <DoubleLeftOutlined />}
                    {viewBenefitsCharge
                      ? "Mostrar todos os benefícios"
                      : "Mostrar benefícios favoritos"}
                    {viewBenefitsCharge && <DoubleRightOutlined />}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        )}
        loading={start || expenseReport.loading}
        pagination={{ defaultPageSize: 1000 }}
        columns={columns}
        scroll={{ y: 400 }}
        dataSource={
          carFleet
            ? filterVehicles.length > 0
              ? filterVehicles
              : data
            : filterEmployees.length > 0
              ? filterEmployees
              : data
        }
        bordered
      />
    );
  }, [data, filterEmployees, filterVehicles]);

  const onEditRow = (key, index, value, item, field = "value") => {
    let newIndex = carFleet
      ? data.findIndex((x) => x.vehicle.id === item)
      : data.findIndex((x) => x.employee.id === item);
    if (data[newIndex] && data[newIndex][key]) {
      const newData = [...data];
      newData[newIndex][key][field] = value;
      setData(newData);
    }
  };

  const onConfirm = () => {
    const checkCompleteBalance = () => {
      const completeBalance = benefits
        .filter((x) => x.completeBalance)
        .map((x) => ({ benefit: x.benefit, name: `benefit_${x.benefit}` }));
      if (completeBalance && completeBalance.length > 0) {
        for (var c of completeBalance) {
          if (data.filter((x) => x[c.name]?.total > 0)?.length > 0) {
            return true;
          }
        }
        return false;
      }
    };

    if (sumTotal === 0) {
      notification.error({
        message: "Carga com valor zerado"
      });
      return;
    }
    if (checkCompleteBalance()) {
      setIsCompleteModalVisible(true);
    } else {
      setIsConfirmModalVisible(true);
    }
  };
  const prepareFileErrors = () => {
    let ws_data = [];
    importReport.forEach((d) => {
      let tmp = d;

      ws_data.push(tmp);
    });
    return ws_data;
  };
  const exportErrors = () => {
    saveFileResume(prepareFileErrors(), "Erros", "Erros");
  };

  const createRevision = async (completeBalance = false) => {
    const createCheckout = async () => {
      const charges = await pendingCharges.load();
      const discounts = await pendingSolicitation.load(login.company.id);
      const fee = charges ? charges.fee : 0;
      const value = completeBalance ? sumTotalOnApprove : sumTotal;
      const taxInvoice =
        login.company.taxInvoice && login.company.taxInvoice > 0
          ? login.company.taxInvoice
          : 0;
      const available =
        selectedBenefit || login.company.benefitAccount
          ? balance.company.subBalances.filter(
              (b) => b.benefit == selectedBenefit
            )[0].available
          : balance.company.available;
      let discount = available > value + fee ? value + fee : available;
      const total = value + fee + taxInvoice;

      const fields = [
        { label: "Valor", value: value },
        { label: "Taxa", value: fee, details: charges },
        {
          discount: true,
          label: "Desconto",
          value: discount,
          details: discounts
        },
        {
          total: true,
          discount: true,
          hasPayment: value + fee - discount > 0,
          label: "Valor Total",
          value: total - discount
        },
        {
          total: true,
          discount: false,
          hasPayment: value + fee > 0,
          label: "Valor Total",
          value: total
        }
      ];
      if (taxInvoice > 0) {
        fields.splice(2, 0, { label: "Taxa Extra", value: taxInvoice });
      }
      if (login.company.benefitAccount) {
        fields.splice(1, 0, {
          label: translate("balance"),
          valueName: selectedBenefit
            ? allbenefits.filter((b) => b.benefit === selectedBenefit)[0].name
            : `${translate("balance")} Geral`
        });
      }
      return fields;
    };

    const createLines = () => {
      const lines = [];
      data.forEach((x) => {
        benefits.forEach((b) => {
          if (x[b.key].value > 0) {
            const pendingExpense =
              x[b.key].pendingExpense != null ? x[b.key].pendingExpense : 0;
            var line = {
              benefit: b.benefit,
              value:
                b.completeBalance && completeBalance
                  ? x[b.key].total - pendingExpense
                  : x[b.key].value,
              total: b.completeBalance ? x[b.key].total : x[b.key].value,
              balance:
                b.completeBalance && completeBalance
                  ? 0
                  : b.completeBalance
                    ? x[b.key].balance
                    : null,
              balanceDate: b.completeBalance
                ? moment().format("YYYY-MM-DD HH:mm:ss")
                : null,
              pendingExpense: pendingExpense
            };
            if (carFleet) {
              lines.push({
                ...line,
                vehicle: x.vehicle.id,
                vehicleLicensePlate: x.vehicle.licensePlate,
                vehicleEnabled: x.vehicle.enabled,
                vehicleName: x.vehicle.name,
                vehicleCompany: login.company.id,
                vehicleAccount: x.vehicle.account,
                vehicleCode: x.vehicle.code,
                completeBalance: b.completeBalance
              });
            } else {
              lines.push({
                ...line,
                user: x.employee.id
              });
            }
          }
        });
      });
      return lines;
    };

    return {
      request: {
        url: "/api/solicitation/" + (carFleet ? "vehicle" : ""),
        method: "post"
      },
      body: {
        solicitation: {
          lines: createLines(),
          discount: true,
          businessUnit: state.businessUnit,
          benefit: selectedBenefit,
          completeBalance: completeBalance
        }
      },
      checkout: await createCheckout(),
      type: "charge",
      successMessage: "Pedido de carga realizado com sucesso."
    };
  };

  const processImportFile = {
    onImport: () => {
      const result = processImportFile.doImport(
        data,
        benefits,
        importData.text
      );
      if (result.length > 0) {
        setData(result);
        setImportData({ show: false });
      }
    },
    onOpenImport: () => {
      setImportData({
        show: true,
        text: carFleet
          ? importFile.createExampleVehicle(data, benefits)
          : importFile.createExample(data, benefits),
        type: carFleet ? "licensePlate" : "email"
      });
    },
    doImport: (data, benefits, text) => {
      const { content, keys } = carFleet
        ? importFile.doImportVehicle(data, text)
        : importFile.doImport(data, text);
      const result = processImportFile.writeContent(
        benefits,
        content,
        keys,
        carFleet
      );
      if (!result.error) {
        if (result.importReport.find((r) => r.Erro)) {
          let errorCount = result.importReport.reduce(
            (a, b) => (b.Erro ? a + 1 : a),
            0
          );
          notification.error({
            message: `Problema na importação de ${errorCount} usuário(s)`
          });
        }

        setImportReport([...result.importReport]);
        return processImportFile.updateContent(data, result.data);
      } else {
        notification.error({
          message: result.error
        });
        return [];
      }
    },
    extractBenefits: (doc) => {
      // Constrói os benefícios
      let benefits = Array.from(doc.getElementsByClassName("benefit")).map(
        (b) => {
          let splits = b.innerText.split(". ");
          let benefit = { id: splits[0], description: splits[1] };
          return benefit;
        }
      );

      return benefits;
    },
    writeContent: (benefits, content, keys, carFleet = false) => {
      // Verifica o cabeçalho do campo chave
      let headerKey = content.header.key.replace(/-/g, "").toLowerCase();
      if (
        headerKey === "cpf" ||
        headerKey === "email" ||
        headerKey === "licenseplate" ||
        headerKey === "code"
      ) {
        headerKey = headerKey === "licenseplate" ? "licensePlate" : headerKey;

        // Função auxiliar para encontrar id do usuário
        let itemId = (key) => {
          if (headerKey === "cpf") {
            key = clearFormatedCPF(key);
            return keys[headerKey][key.padStart(11, "0")];
          } else {
            return keys[headerKey][key];
          }
        };

        // Verifica as colunas adicionais do cabeçalho
        if (content.header.columns) {
          // TODO: tratar colunas não representam benefícios
          let benefitsColumns = content.header.columns.map((c) =>
            c.match(/-?\d+/g).length > 0 ? c.match(/-?\d+/g)[0] : null
          );

          // Verifica as linhas adicionais
          if (content.rows) {
            // Para cada linha adicional
            let data = [];
            let importReport = [];

            content.rows.forEach((row) => {
              let allBenefits = {};
              let id = itemId(row.key);
              for (
                let i = 0;
                i < row.values.length && i < benefitsColumns.length;
                i++
              ) {
                let benefitId = benefitsColumns[i]
                  ? parseInt(benefitsColumns[i])
                  : null;
                if (benefitId) {
                  let benefit = benefits.find((x) => x.benefit === benefitId);
                  if (benefit) {
                    let value =
                      row.values[i].indexOf(",") > -1
                        ? row.values[i].replace(".", "").replace(",", ".")
                        : row.values[i];
                    //doc.getElementById("value." + id + "." + benefit).value = value;
                    let benefitInfo = {
                      benefit: benefit,
                      value: parseFloat(value)
                    };
                    data.push({
                      id: id,
                      ...benefitInfo
                    });
                    allBenefits[
                      `(${benefitId}) - ${benefitInfo.benefit.name}`
                    ] = benefitInfo.value;
                  }
                }
              }
              importReport.push({
                [headerKey]: row.key,
                ...allBenefits,
                Erro: id
                  ? ""
                  : `${carFleet ? "Veículo" : "Usuário"} não encontrado`
              });
            });

            return {
              result: content.rows.length,
              data: data,
              importReport
            };
          } else {
            return {
              error: "Não há linhas adicionais além do cabeçalho."
            };
          }
        } else {
          return {
            error: "Não há colunas adicionais representando os benefícios."
          };
        }
      } else {
        return carFleet
          ? { error: "A primeira coluna precisa ser 'placa' ou 'código'." }
          : { error: "A primeira coluna precisa ser 'cpf' ou 'email'." };
      }
    },
    updateContent: (data, result) => {
      const newData = [...data];
      result.forEach((r) => {
        const find = newData.find(
          (x) => (carFleet ? x.vehicle.id : x.employee.id) === r.id
        );
        if (find) {
          if (!r.benefit.completeBalance) find[r.benefit.key].value = r.value;
          else find[r.benefit.key].total = r.value;
        }
      });
      return newData;
    }
  };

  const loadExpenseReport = async () => {
    if (expense.createSolicitation) {
      setExpenseReport({ data: [], loading: true });
      const data = await getExpenseReport(expense.filter);
      setExpenseReport({ data: data, loading: false });
    }
  };

  React.useEffect(() => {
    loadBenefits();
  }, [selectedBenefit]);

  React.useEffect(() => {
    let active = true;
    const loadCompletedBalance = async () => {
      const completeBalance = benefits.filter((x) => x.completeBalance);
      if (completeBalance && completeBalance.length > 0) {
        for (let y = 0; y < completeBalance.length; y++) {
          for (let i = 0; i < data.length && active; i++) {
            const entity = carFleet ? data[i].vehicle.id : data[i].employee.id;
            const balances = carFleet
              ? await getVehicleBalance(entity)
              : await getEmployeeBalance(entity);
            if (balances && active) {
              const balance = balances.find(
                (b) => b.benefit === completeBalance[y].benefit
              );
              const balanceFinal = balance ? balance.balance : 0;
              onEditRow(
                completeBalance[y].key,
                i,
                balanceFinal,
                entity,
                "balance"
              );
              if (balance) {
                const total = data[i][completeBalance[y].key].total;
                const sum = total - balanceFinal < 0 ? 0 : total - balanceFinal;
                onEditRow(completeBalance[y].key, i, sum, entity, "value");
              }
              onEditRow(
                completeBalance[y].key,
                i,
                sumPendingExpense(
                  entity,
                  balanceFinal,
                  completeBalance[y].benefit
                ),
                entity,
                "pendingExpense"
              );
            }
          }
        }
      }
    };

    if (!start) {
      loadCompletedBalance();
    }
    return () => {
      active = false;
    };
  }, [start]);

  React.useEffect(() => {
    loadData();
  }, [benefits]);

  React.useEffect(() => {
    // return () => {
    //    console.log('ENTROU');
    //    clearExpenseFilter();
    // };
  }, [expense]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={
          "Defina o valor da carga por " +
          (carFleet ? "veículo" : "colaborador")
        }
        subtitle={
          carFleet
            ? ""
            : "Caso o benefício não tenha valor mínimo, a carga vai para Benefícios Flexíveis."
        }
      >
        <Row gutter={12} align="middle">
          {login.company.benefitAccount || selectedBenefit ? (
            <>
              <Col>
                <CardCompanyBalance
                  benefit={selectedBenefit}
                  useBenefit={login.company.benefitAccount ? true : false}
                />
              </Col>
              <Col>
                <CardCompanyBalance
                  type={"discount"}
                  benefit={selectedBenefit}
                  useBenefit={login.company.benefitAccount ? true : false}
                />
              </Col>
            </>
          ) : (
            <>
              <Col xl={4}>
                <CardCompanyBalance
                  benefit={selectedBenefit}
                  useBenefit={login.company.benefitAccount ? true : false}
                />
              </Col>
              <Col xl={4}>
                <CardCompanyBalance
                  type={"discount"}
                  benefit={selectedBenefit}
                  useBenefit={login.company.benefitAccount ? true : false}
                />
              </Col>
            </>
          )}
          <Col xl={4}>
            <CardCompanyBalance type={"charge"} />
          </Col>
          <Col xl={4}>
            <CardInfo title="Valor do Pedido" value={formatMoney(sumTotal)} />
          </Col>
          <Col xl={4}>
            <CardInfo
              title={carFleet ? "Veículos" : "Colaboradores"}
              value={data.length}
            />
          </Col>
          <Col style={{ textAlign: "center", flex: 1 }}>
            <Button
              block
              icon={<FileExcelOutlined />}
              type="primary"
              onClick={processImportFile.onOpenImport}
            >
              Importar
            </Button>
          </Col>
        </Row>
        <Col xl={24}>{messageMinValue}</Col>
      </Header>
      <Content>{table}</Content>
      <Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div>
          <Button
            disabled={importReport.length == 0}
            style={{ marginRight: 10 }}
            type={"primary"}
            onClick={() => exportErrors()}
          >
            Exportar Erros
          </Button>

          <Typography.Text>
            Sucesso:{" "}
            {importReport
              ? importReport.reduce((a, b) => (b.Erro ? a : a + 1), 0)
              : 0}{" "}
          </Typography.Text>
          <Typography.Text>
            Erro:{" "}
            {importReport
              ? importReport.reduce((a, b) => (b.Erro ? a + 1 : a), 0)
              : 0}
          </Typography.Text>
        </div>
        <Tooltip
          title={
            cannotOrder
              ? "Não é possível fazer o pedido enquanto houver dados sendo carregados."
              : ""
          }
        >
          <Button
            className="btn-save"
            disabled={cannotOrder}
            type="primary"
            icon={<SaveOutlined />}
            onClick={onConfirm}
          >
            {cannotOrder ? "Carregando..." : "Fazer Pedido"}
          </Button>
        </Tooltip>
      </Footer>
      {
        <Modal
          width={400}
          open={isCompleteModalVisible}
          onCancel={() => setIsCompleteModalVisible(false)}
          title="Calcular complemento"
          cancelText="Calcular agora"
          footer={[
            <Button
              key="back"
              onClick={() => {
                setCompleteBalance(false);
                completeModalHandler();
              }}
            >
              Agora
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setCompleteBalance(true);
                completeModalHandler();
              }}
            >
              Na data de aprovação
            </Button>
          ]}
        >
          <p>Quando deseja calcular o complemento de limite</p>
        </Modal>
      }
      {
        <Modal
          width={400}
          open={isConfirmModalVisible}
          title="Deseja continuar?"
          okText="Sim"
          cancelText="Não"
          confirmLoading={isConfirmLoading}
          onOk={async () => {
            setIsConfirmLoading(true);

            let state = { data: await createRevision(completeBalance) };
            if (advance) {
              state.selectedAdvanceIds = selectedAdvanceIds;
            }
            navigate("/solicitation/charge/revision", {
              state: state
            });
          }}
          onCancel={() => {
            setIsConfirmModalVisible(false);
          }}
        >
          <p>Após a confirmação, você irá fazer a revisão do seu pedido.</p>
        </Modal>
      }
      {applyAll.show && (
        <Modal
          width={400}
          title={applyAll.title}
          open={applyAll.show}
          onOk={applyAllFunctions.onConfirmApplyAll}
          onCancel={() => applyAllFunctions.onShowApplyAll(false)}
        >
          <InputCurrency
            value={applyAll.value}
            onChange={(value) =>
              setApplyAll((prev) => ({ ...prev, value: value }))
            }
          />
        </Modal>
      )}
      {importData.show && (
        <Modal
          width={800}
          title={"Importação"}
          open={importData.show}
          onOk={processImportFile.onImport}
          onCancel={() => setImportData({ show: false })}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Radio.Group
                onChange={(e) =>
                  setImportData((prev) => ({
                    ...prev,
                    text: carFleet
                      ? importFile.createExampleVehicle(
                          data,
                          benefits,
                          e.target.value
                        )
                      : importFile.createExample(
                          data,
                          benefits,
                          e.target.value
                        ),
                    type: e.target.value
                  }))
                }
                defaultValue={carFleet ? "licensePlate" : "email"}
                value={importData.type}
                buttonStyle="solid"
                style={{ width: "50%" }}
              >
                {carFleet ? (
                  <Fragment>
                    <Radio.Button value="licensePlate" style={{ width: "50%" }}>
                      Placa
                    </Radio.Button>
                    <Radio.Button value="code" style={{ width: "50%" }}>
                      Código
                    </Radio.Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Radio.Button value="email" style={{ width: "50%" }}>
                      Email
                    </Radio.Button>
                    <Radio.Button value="cpf" style={{ width: "50%" }}>
                      CPF
                    </Radio.Button>
                  </Fragment>
                )}
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Typography.Text>
                Copie o conteúdo de sua planilha
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Input.TextArea
                rows={10}
                value={importData.text}
                onChange={(e) =>
                  setImportData((prev) => ({ ...prev, text: e.target.value }))
                }
              ></Input.TextArea>
            </Col>
          </Row>
        </Modal>
      )}
    </Layout>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationSaveScreen);
