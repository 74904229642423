import React from "react";
import { connect } from "react-redux";
import { Layout, Table, Button, Space, Tooltip, Checkbox, Modal } from "antd";
import {
  PlusOutlined,
  BarsOutlined,
  ExportOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  FormatDate,
  Header,
  TableSearch,
  ColumnEmployeeStatus,
  BatchUpdate
} from "components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllUsers, getUserKycStatus } from "services/user-service";
import { saveFileResume, formatDate } from "utils";
import { bindActionCreators } from "redux";
import * as balanceAction from "actions/balance-action";
import { MdStar as AdminIcon } from "react-icons/md";
import * as authAction from "actions/auth-action";
import _, { set } from "lodash";
import moment from "moment";
import environment from "env";
import usePage from "hooks/usePage";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...authAction,
      ...balanceAction
    },
    dispatch
  );

const DataFetch = {
  data: [],
  loading: false
};

const UserScreen = (props) => {
  const {
    login,
    loginCompanyAction,
    compact = false, //compact
    onEditCompact = () => {}, //compact
    refresh = false, //compact
    onLoadUsers = () => {}, //compact
    getCompanyBalanceAction
  } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [initialData, setInitialData] = React.useState(DataFetch);
  const [subRoutes, setSubRoutes] = React.useState([]);
  const [itensUpdateStatus, setItensUpdateStatus] = React.useState([]);
  const { benefit } = login.user.feature;
  const [pageParams, setPageParams] = useSearchParams();

  const [getChildrenCompanies, setGetChildrenCompanies] = React.useState(
    pageParams.get("getChildrenCompanies")
      ? JSON.parse(pageParams.get("getChildrenCompanies"))
      : false
  );
  const load = async (filter = "") => {
    setData((x) => ({ ...x, loading: true }));
    if (login.company.benefitAccount) await getCompanyBalanceAction();
    const res = await getAllUsers({ filter: filter, getChildrenCompanies });
    setData({ data: res, loading: false });
    setInitialData({ data: res, loading: false });
    // setStartBatch(true);
    setItensUpdateStatus(res.slice(0, 10));
    if (compact) {
      onLoadUsers(res);
      // setStartBatch(false)
    }
  };
  const localLoad = (e) => {
    setData((prev) => ({ ...prev, data: e }));
    setItensUpdateStatus(e.slice(0, 10));
  };
  const { page, search, changePage, localSearch } = usePage(load, localLoad);

  const onNew = () => {
    navigate(`/user/add`);
  };

  const onBatch = () => {
    navigate(`/user/batch`);
  };
  const editUser = async (record, value) => {
    if (login.company.isMatriz && login.company.id !== record.company.id) {
      editChildrenCompanyUser(record, value);
    } else {
      const params = pageParams.size > 0 ? `?${pageParams.toString()}` : "";
      compact ? onEditCompact(value) : navigate(`/user/${value + params}`);
    }
  };

  const editChildrenCompanyUser = async (record, value) => {
    Modal.confirm({
      width: 500,
      okText: "Continuar",
      cancelText: "Voltar",
      title: `Você será direcionado para a empresa "${record.company.name}" para editar ${record.userSex.code === "M" ? "o" : "a"} ${
        record.userSex.code === "M" ? "colaborador" : "colaboradora"
      } "${record.name}".`,
      onOk: async () => {
        setTimeout(async () => {
          await loginCompanyAction(record.company.id);
          navigate(`/user/${value}`);
          window.location.reload();
        }, 1000);
      },
      onCancel: () => {
        console.log(record);
      }
    });
  };

  const loadSubRoutes = () => {
    let routes = [];
    if (login.company.master || login.user.admin) {
      routes = [
        { label: "Outros Gerentes", route: "/user/manager/others" },
        { label: "Gerente de Cartão", route: "/user/manager/card" },

        { label: "Gerente de Cadastro", route: "/user/manager/register" },
        { label: "Equipes", route: "/user/equip" }
      ];
    }

    if (benefit) {
      routes.push({
        label: "Gerente de Benefícios",
        route: "/user/benefit/manager"
      });
    }

    if ((login.children.has && login.company.master) || login.user.admin) {
      routes.push({
        label: "Administradores",
        route: "/user/parent/administrator"
      });
    }
    setSubRoutes(routes);
  };

  const prepareFileResume = () => {
    let ws_data = [];
    data.data.forEach((d) => {
      let tmp = {
        Nome: d.name,
        Email: d.email,
        CPF: d.cpf,
        Nascimento: d.birthday ? formatDate(d.birthday) : "",
        Telefone: d.phone,
        Sexo: d.userSex.code,
        ...((login.company.master || login.company.enableCode) && {
          Código: d.code
        }),
        Rua: d.address ? d.address.address : "",
        Número: d.address ? d.address.number : "",
        Complemento: d.address ? d.address.complement : "",
        Bairro: d.address ? d.address.neighborhood : "",
        CEP: d.address ? d.address.zipcode : "",
        Cidade: d.address ? d.address.city : "",
        Estado: d.address ? d.address.state : "",
        "Nome da Mãe": d.mother,
        Administrador: d.userType.code == "U" ? "Sim" : "Não",
        Cartão: d.cardStatus.name,
        "Cartões Ativos": d.cardsInfo.numberOfActiveCards,
        "Total de Cartões": d.cardsInfo.numberOfCards,
        "Validação de Dados": d.validation ? d.validation.name : "",
        //"Status de Carga": d.chargeStatus.name,
        Ativo: d.enabled ? "Sim" : "Não",
        Empresa: d.company.name
      };
      if (login.company.master) {
        tmp["Tipo"] = d.companyExternal.companyType.name;
      }
      ws_data.push(tmp);
    });
    return ws_data;
  };

  const onExport = () => {
    saveFileResume(prepareFileResume(), "Colaboradores", "Colaboradores");
  };

  const validValue = (value) => {
    if (value === "" || value === null) {
      return (
        <Tooltip title={"Dado obrigatório não cadastrado!"}>
          <InfoCircleOutlined className="color-warning" />
        </Tooltip>
      );
    } else {
      return value;
    }
  };

  const setItensUpdate = (itens) => {
    let dataAux = [...data.data];
    for (let data of itens) {
      dataAux.filter((d) => d.id == data.id)[0] = data;
    }
    setData((x) => ({ ...x, data: dataAux }));
  };

  React.useEffect(() => {
    loadSubRoutes();
  }, [login]);

  React.useEffect(() => {
    if (refresh) {
      load();
    }
  }, [refresh]);

  React.useEffect(() => {
    if (
      !login.company.master ||
      (pageParams.get("page") && !pageParams.get("search"))
    ) {
      load();
    }
  }, [getChildrenCompanies]);

  const table = (
    <React.Fragment>
      <BatchUpdate
        onChange={(e) => setItensUpdate(e)}
        onChangeItem={(e) => (e.onRequest = false)}
        start={itensUpdateStatus}
        filter={(e) => e.validation && e.validation.onRequest}
        data={itensUpdateStatus}
        request={getUserKycStatus}
        fieldKey="id"
        fieldResponse="validation"
      />
      {!compact && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "16px"
          }}
        >
          <TableSearch
            request={login.company.master ? search : null}
            data={data.data}
            initialData={initialData.data}
            filter={["name", "rawCpf", "email", ["company", "name"]]}
            onSearch={(e) => localSearch(e)}
          />
          {login.company.isMatriz && (
            <Checkbox
              checked={getChildrenCompanies}
              style={{ alignSelf: "flex-end" }}
              onChange={() => {
                setGetChildrenCompanies(!getChildrenCompanies);

                setPageParams((p) => {
                  p.set("getChildrenCompanies", !getChildrenCompanies);
                  p.delete("search");
                  p.set("page", 1);
                  return p;
                });
              }}
            >
              Buscar em filial
            </Checkbox>
          )}
        </div>
      )}
      <Table
        scroll={{ y: 650, x: 1000 }}
        dataSource={data.data}
        onChange={(page) => {
          changePage(page.current);
        }}
        loading={data.loading}
        pagination={{
          current: page,
          onChange: (page, pageSize) =>
            setItensUpdateStatus(
              data.data.slice((page - 1) * pageSize, page * pageSize)
            )
        }}
        bordered
      >
        {login.company.multicompany && (
          <React.Fragment>
            <Table.Column
              width={350}
              title="Empresa"
              dataIndex={["companyExternal", "name"]}
              key="companyExternal.name"
            />
            <Table.Column
              width={150}
              align="center"
              title="Tipo"
              dataIndex={["companyExternal", "companyType", "name"]}
              key="companyExternal.companyType.name"
              filters={_.uniqWith(
                data.data.map((d) => {
                  return {
                    text: d.companyExternal.companyType.name,
                    value: d.companyExternal.companyType.code
                  };
                }),
                _.isEqual
              )}
              onFilter={(value, record) =>
                record.companyExternal.companyType.code === value
              }
            />
          </React.Fragment>
        )}
        <Table.Column
          width={350}
          title="Nome"
          dataIndex="name"
          key="name"
          render={(value, record) => (
            <Space>
              {validValue(value)}
              {record.userType.code === "U" && (
                <Tooltip title="Administrador">
                  <AdminIcon color="#faad14" />
                </Tooltip>
              )}
              {moment().diff(record.birthday, "years") < 18 &&
                (record.mother === null || record.mother === "") && (
                  <Tooltip title="Colaborador menor de idade sem nome da mãe cadastrado.">
                    <InfoCircleOutlined className="color-warning" />
                  </Tooltip>
                )}
            </Space>
          )}
        />
        <Table.Column
          width={350}
          title="Email"
          dataIndex="email"
          key="email"
          render={(value) => validValue(value)}
        />
        <Table.Column
          width={150}
          align="center"
          title="CPF"
          dataIndex="cpf"
          key="cpf"
          render={(value) => validValue(value)}
        />
        <Table.Column
          width={150}
          align="center"
          title="Nascimento"
          dataIndex="birthday"
          key="birthday"
          render={(value) =>
            value ? validValue(<FormatDate value={value} />) : validValue(value)
          }
        />
        <Table.Column
          width={70}
          align="center"
          title="Sexo"
          dataIndex={["userSex", "code"]}
          key="userSex.code"
          render={(value) => validValue(value)}
        />
        <Table.Column
          width={150}
          align="center"
          title="Telefone"
          dataIndex="phone"
          key="phone"
        />

        <Table.Column
          width={180}
          align="left"
          title="Cartões Ativos"
          dataIndex={["cardsInfo", "numberOfActiveCards"]}
          key="cardsInfo.numberOfActiveCards"
          render={(value) => validValue(value)}
        />
        <Table.Column
          width={120}
          align="left"
          title="Total de Cartões"
          dataIndex={["cardsInfo", "numberOfCards"]}
          key="cardsInfo.numberOfCards"
          render={(value) => validValue(value)}
        />
        {login.company.enableCode && (
          <Table.Column
            width={120}
            title="Código"
            dataIndex="code"
            key="code"
          />
        )}
        <Table.Column
          width={180}
          fixed="right"
          align="center"
          title="Status"
          key="status"
          filters={[
            {
              text: "Ativo",
              value: true
            },
            {
              text: "Inativo",
              value: false
            }
          ]}
          onFilter={(value, record) => record.enabled == value}
          render={(value) => <ColumnEmployeeStatus data={value} />}
        />
        {!compact && (
          <Table.Column
            fixed="right"
            width={80}
            align="center"
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value, row) => (
              <Button
                size="small"
                type={"link"}
                loading={login.isProcessing}
                onClick={() => editUser(row, value)}
              >
                Editar
              </Button>
            )}
          />
        )}
      </Table>
    </React.Fragment>
  );

  return compact ? (
    table
  ) : (
    <Layout className="alymente-layout">
      {!compact && <Header title="Colaboradores" routes={subRoutes} />}
      <Content style={compact ? { paddingRight: 8, paddingLeft: 8 } : {}}>
        {table}
      </Content>
      {!compact && (
        <Footer>
          <Space align="end">
            {(login.company.master || login.user.admin) && (
              <Button
                type="primary"
                icon={<ExportOutlined />}
                onClick={onExport}
              >
                Exportar
              </Button>
            )}
            <Button type="primary" icon={<BarsOutlined />} onClick={onBatch}>
              Edição em Lote
            </Button>
            <Button type="primary" icon={<PlusOutlined />} onClick={onNew}>
              Novo Colaborador
            </Button>
          </Space>
        </Footer>
      )}
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
