import PromisePool from "@supercharge/promise-pool/dist";
import moment from "moment";
import { getRefundById } from "./refund-service";

export const getRefundsCompleteInfo = async (refundIds) => {
  let { results: completeRefundInfo, errors } = await PromisePool.for(refundIds)
    .withConcurrency(3)
    .useCorrespondingResults()
    .process(async (refundId) => {
      let res = await getRefundById(refundId);
      return {
        ...res.refund,
        createdAt: moment
          .unix(res.refund.createdAt / 1000)
          .format("DD/MM/YYYY"),
        value: res.refund.value ?? 0,
        valueNF: res.refund.valueNF ?? 0,
        status: res.refund.status?.name,
        option: res.refund.option?.name,
        companies: res.refund.companies
          .map((companies) => companies.name)
          .join(", ")
      };
    });

  const hasError = errors.length > 0;
  if (hasError) throw new Error("Erro ao buscar relatório de limite.");
  return completeRefundInfo.flat();
};

export const mapRefundDataToSheetFormat = ({
  createdAt,
  employee,
  value,
  valueNF,
  status,
  benefit,
  option,
  companies,
  description,
  detalhes,
  companyClient
}) => {
  return {
    Data: moment.unix(createdAt / 1000).format("DD/MM/YYYY"),
    Colaborador: employee,
    "Valor Solicitado": value ?? 0,
    "Valor Aprovado": valueNF ?? 0,
    Status: status,
    Benefício: benefit,
    Opção: option.name,
    "Empresa(s)": companies.map((companies) => companies.name).join(", "),
    Descrição: description,
    Motivo: detalhes,
    Empresa: companyClient,
  };
};
