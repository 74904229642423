import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Table,
  Button,
  Tooltip,
  Tag,
  Typography,
  Space,
  Row,
  Col,
  Alert,
  notification
} from "antd";
import {
  CloseCircleOutlined,
  DollarCircleOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { getAllRefunds, finishRefunds, getRefundReportData } from "services/refund-service";
import {
  getLastBatch,
  closeLastBatch,
  openBatch
} from "services/transfeera-service";
import { Header, FormatDate, FormatMoney } from "components";
import { exportXls, formatDate, handlerError } from "utils/index";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  getRefundsCompleteInfo,
  getSheetTitles,
  mapRefundDataToSheetFormat
} from "services/corporate-refund-report-service";
import PeriodReport from "components/report/period-report";
import { getCompanyBenefits } from "services/company-service";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const DataBatchFetch = {
  data: null,
  loading: true
};

const RefundScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch);
  const [batch, setBatch] = React.useState(DataBatchFetch);
  const [reportLoading, setReportLoading] = React.useState(false);
  const [benefits, setBenefits] = React.useState([]);
  const navigate = useNavigate();
  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllRefunds();
    setData({ data: res, loading: false });
    setBenefits(await getCompanyBenefits(true));
  };

  const loadBatch = async () => {
    setBatch((x) => ({ ...x, loading: true }));
    const res = await getLastBatch();
    setBatch((x) => ({ loading: false, data: res ? res : null }));
  };

  const onCloseBatch = async () => {
    setBatch((x) => ({ ...x, loading: true }));
    await closeLastBatch();
    await loadBatch();
  };

  const onOpenBatch = async () => {
    setBatch((x) => ({ ...x, loading: true }));
    await openBatch();
    await loadBatch();
  };

  const onReportGeneration = async (startPeriod,
    endPeriod,
    getChildrenCompanies,
    getAllPeriod,
    benefits,
    childrens) => {

    setReportLoading(true);
    try {
      const companyData = getChildrenCompanies
        ? login.children.data
          .filter(
            (c) =>
              childrens.filter((ch) => parseInt(ch) == c.id).length > 0 ||
              login.company.id == c.id
          )
          .map((c) => ({ id: c.id, companyName: c.name }))
        : [{ id: login.company.id, companyName: login.company.name }];

      const refundReportData = await getRefundReportData(
        companyData,
        startPeriod,
        endPeriod,
        getAllPeriod,
        benefits
      );

      await createRefundReport(refundReportData);
    } catch (e) {
      console.log(e)
      console.log(JSON.stringify(e));
      notification.error({ message: e.message });
    }
    setReportLoading(false);
  };
  
  const onFinishRefunds = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await finishRefunds();
    await load();
  };

  const createRefundReport = (dataFilter) => {
    var refundReportData = dataFilter.map(mapRefundDataToSheetFormat);

    if (!login.company.selectCompanyGroupRefund) {
      refundReportData = refundReportData.map((item) => {
        const { "Empresa(s)": _, ...rest } = item;
        return rest;
      });
    }

    exportXls().createAndSaveFile(
      ["Relatório"],
      { Relatório: refundReportData },
      "relatorio_reembolso_corporativo",
      "Relatório Reembolso Corporativo"
    );
  }

  React.useEffect(() => {
    load();
    loadBatch();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header title="Reembolso">
        {/* {(login.company.master && !batch.loading) &&
                    <Row>
                        <Col>
                            {batch.data ?
                                <Alert message={`Lote: ${batch.data.batchId}`} type="success" showIcon />
                                :
                                <Alert message="Você não possui um lote aberto!" type="info" showIcon />
                            }
                        </Col>
                    </Row>
                } */}
      </Header>
      <Content>
        <Table
          scroll={{ x: login.company.master ? 2500 : 1500, y: 650 }}
          dataSource={data.data}
          loading={data.loading || reportLoading}
          pagination={false}
          bordered
        >
          <Table.Column
            fixed="left"
            width={60}
            align="center"
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />

          <Table.Column
            fixed="left"
            width={110}
            title="Colaborador"
            dataIndex="employee"
            key="employee"
            render={(value, row) => {
              if (!row.updatedEmployee) return value;
              else {
                return (
                  <Tooltip
                    placement="bottom"
                    title={
                      "Usuário atualizado em " +
                      formatDate(row.dateUpdatedUser, "DD/MM/YYYY")
                    }
                  >
                    <Text mark>{value}</Text>
                  </Tooltip>
                );
              }
            }}
          />
          {login.company.master && (
            <React.Fragment>
              <Table.Column
                width={110}
                title="Empresa"
                dataIndex="companyClient"
                key="companyClient"
              />
              <Table.Column
                width={50}
                align="right"
                title="Taxa"
                dataIndex="fee"
                key="fee"
                render={(value) => <FormatMoney value={value} />}
              />
              <Table.Column
                width={50}
                align="center"
                title="Tipo"
                dataIndex={"corporate"}
                key="corporate"
                filters={[
                  { text: "Corporativo", value: true },
                  { text: "Colaborador", value: false }
                ]}
                onFilter={(value, record) => record.corporate === value}
                defaultFilteredValue={login.company.master ? [false] : null}
                render={(value) => (value ? "Corporativo" : "Colaborador")}
              />
            </React.Fragment>
          )}

          <Table.Column
            width={50}
            align="right"
            title="Valor Solicitado"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={50}
            align="right"
            title="Valor Aprovado"
            dataIndex="valueNF"
            key="valueNF"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={100}
            align="center"
            title="Status"
            dataIndex="status"
            key="status"
            filters={
              login.company.master
                ? _.uniqWith(
                  data.data.map((d) => {
                    return { text: d.status, value: d.status };
                  }),
                  _.isEqual
                )
                : null
            }
            defaultFilteredValue={
              login.company.master
                ? [
                  "Validação",
                  "Revisão",
                  "Aprovado",
                  "Confirmado",
                  "Transferindo",
                  "Recebido",
                  "Pagando",
                  "Pago",
                  "Cancelado",
                  "Re-Tentativa"
                ]
                : null
            }
            onFilter={(value, record) => {
              return login.company.master ? record.status === value : true;
            }}
            render={(value, row) => (
              <Tag color={row.category}>{value.toUpperCase()}</Tag>
            )}
          />
          <Table.Column
            width={85}
            align="center"
            title="Despesa"
            key="expense"
            render={(value) =>
              value.expenseType ? value.expenseType : value.benefit
            }
          />
          <Table.Column
            width={80}
            align="center"
            title="Opção"
            dataIndex={["option", "name"]}
            key="option.name"
          />
          {login.company.selectCompanyGroupRefund && (
            <Table.Column
              width={100}
              align="left"
              title="Empresa(s)"
              dataIndex="companies"
              key="companies"
              render={(companies) =>
                companies.map((companies) => companies.name).join(", ")
              }
            />
          )}
          <Table.Column
            fixed="right"
            width={50}
            align="center"
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value) => (
              <Button
                size="small"
                type={"link"}
                onAuxClick={(e) => window.open("/refund/" + value)}
                onClick={() => navigate(`/refund/${value}`)}
              >
                Detalhes
              </Button>
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Space>
          {!login.company.master && (
            // <Button
            //   loading={data.loading || reportLoading}
            //   type="primary"
            //   icon={<FileExcelFilled />}
            //   onClick={createCorporateRefundReport}
            // >
            //   Exportar Reembolso
            // </Button>
            <PeriodReport
              childrenCompaniesConfig={{ visible: true }}
              loading={reportLoading}
              onReportGeneration={onReportGeneration}
              getAllDateConfig={{ visible: true }}
              isVisible={!login.company.master}
              buttonConfig={{
                icon: <FileExcelOutlined />,
                title: "Exportar Reembolsos"
              }}
              screen={"refund"}
              benefits={benefits}
              childrens={login.children.data}
              company={login.company}
            />
          )}
          {!login.company.master && (
            <Button
              type="primary"
              icon={<DollarCircleOutlined />}
              onClick={() => navigate("/solicitation/refund")}
            >
              Fazer Pedido de Pagamento
            </Button>
          )}
          {login.company.master && (
            <Button
              loading={data.loading}
              disabled={data.loading}
              type="primary"
              icon={<CloseCircleOutlined />}
              onClick={onFinishRefunds}
            >
              Recusar Novos
            </Button>
          )}

          {/* {(login.company.master && !batch.data) &&
                        <Button loading={batch.loading} type="primary" icon={<PlusCircleOutlined />} onClick={onOpenBatch}>Abrir Lote</Button>
                    }
                    {(login.company.master && batch.data) &&
                        <Button loading={batch.loading} type="primary" icon={<CloseCircleOutlined />} onClick={onCloseBatch}>Fechar Lote</Button>
                    } */}
        </Space>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(RefundScreen);
